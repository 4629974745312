import {
  SET_BOOKED_APPOINTMENT_DETAILS,
  SET_BOOKING_CLINICIAN,
  SET_BOOKING_DOCTOR,
  SET_BOOKING_FILTER_DATA, SET_FLOW_TYPE,
  SET_PRE_BOOKED_APPOINTMENT_DATA,
} from 'src/js/actionTypes/BookingActionType';
import { UUID } from 'src/js/endpoints/types';
import { Appointment } from 'src/js/globalTypes';
import { BookingPricingOption, RescheduleType } from 'src/js/endpoints/therapy';
import { SELECTED_APT_KIND_OPTIONS } from 'src/js/actions/BookingActions';
import { AvailableAppointment, AvailableDoctor } from 'src/js/endpoints/appointments';
import { GetParams } from 'components/booking/ClinicianFirst/Search/searchTypes';
import { FlowType } from 'src/js/components/bookingFlow/pcBookingFlowHook';
import { Doctor } from 'src/js/components/dashboard/DashboardTypes';

export type PreBookedAppointment = Appointment & {
  pricing?: BookingPricingOption[]
  selectedRecurringPlan?: BookingPricingOption['frequency']
  rescheduleType?: RescheduleType
  previousAppId?: UUID
  doctor: {
    average: Doctor['average_score']
    doctor: Doctor
  }
}

export type State = {
  doctor_id: number | null,
  clinician: AvailableDoctor | null,
  filterData: GetParams | null,
  // null means that we have not requested API yet
  preBookedAppointment: PreBookedAppointment | AvailableAppointment | null,
  bookedAppointmentDetails: {
    appointment_id: UUID
    appointment_utc_time: string // 2021-05-13T16:30:00+00:00
    has_conflicts: boolean
    bookedAptKind: SELECTED_APT_KIND_OPTIONS
    docName: string
    is_recurring?: boolean
    isReschedule?: boolean
    isFollowup?: boolean
    plan_created: boolean
    // has user just enrolled in mental health program
    newToMh?: boolean
  } | null,
  flowType: FlowType | null,
}

export const initialState: State = {
  doctor_id: null,
  clinician: null,
  filterData: null,
  preBookedAppointment: null,
  bookedAppointmentDetails: null,
  flowType: null,
};

// eslint-disable-next-line default-param-last
function BookingReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case SET_FLOW_TYPE:
      return {
        ...state,
        flowType: action.payload,
      };
    case SET_BOOKING_DOCTOR:
      return {
        ...state,
        doctor_id: action.payload,
      };
    case SET_BOOKING_CLINICIAN:
      return {
        ...state,
        clinician: action.payload,
      };
    case SET_BOOKING_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case SET_BOOKED_APPOINTMENT_DETAILS:
      return {
        ...state,
        bookedAppointmentDetails: action.payload,
      };
    case SET_PRE_BOOKED_APPOINTMENT_DATA:
      return {
        ...state,
        preBookedAppointment: action.payload,
      };
    default:
      return state;
  }
}

export default BookingReducer;

type Actions = {
  type: typeof SET_FLOW_TYPE
  payload: State['flowType']
} | {
  type: typeof SET_BOOKING_DOCTOR
  payload: State['doctor_id']
} | {
  type: typeof SET_BOOKING_CLINICIAN
  payload: State['clinician']
} | {
  type: typeof SET_BOOKING_FILTER_DATA
  payload: State['filterData']
} | {
  type: typeof SET_BOOKED_APPOINTMENT_DETAILS
  payload: State['bookedAppointmentDetails']
} | {
  type: typeof SET_PRE_BOOKED_APPOINTMENT_DATA
  payload: State['preBookedAppointment']
}
