import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors, breakpoints } from '../helpers/plushcare-style-defaults';

// NOTE 1: for the desktop hover transition
// set the box-shadow blur-radius to 0.01px
// to stop button flicker
//
// NOTE 2: for the desktop hover
// add transform: scale(1)
// as this fixes a bug in Chrome
export const buttonTypeStyles = {
  primary: (theme) => css`
    background: ${colors.sunshine};
    color: ${theme.colors.foreground};

    &:before {
      box-shadow: inset 0 0 0.01px ${colors.cloud};
    }

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.foreground};
        transform: scale(1);
      }

      &:hover:before {
        box-shadow: inset 500px 0 0.01px ${colors.cloud};
      }
    }

    &:active {
      background: ${colors.malibu};
      color: ${colors.white}
    }
  `,
  'primary-tertiary': (theme) => css`
    border: 1px solid ${colors.sunshine};
    background: ${colors.white};
    color: ${theme.colors.foreground};

    &:before {
      box-shadow: inset 0 0 0.01px ${colors.sunshine};
    }

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.foreground};
        transform: scale(1);
      }

      &:hover:before {
        box-shadow: inset 500px 0 0.01px ${colors.cloud};
      }
    }

    &:active {
      background: ${colors.malibu};
      color: ${colors.white}
    }
  `,
  secondary: (theme) => css`
    background: ${colors.cloud};
    color: ${theme.colors.foreground};

    &:before {
      box-shadow: inset 0 0 0.01px ${colors.cloud};
    }

    @media (hover: hover) {
      &:hover {
        color: ${colors.cloud};
        transform: scale(1);
      }

      &:hover:before {
        box-shadow: inset 500px 0 0.01px ${theme.colors.foreground};
      }
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white}
    }
  `,
  'secondary-tertiary': (theme) => css`
    border: 1px solid ${colors.cloud};
    background: ${colors.white};
    color: ${theme.colors.foreground};

    &:before {
      box-shadow: inset 0 0 0.01px ${colors.cloud};
    }

    @media (hover: hover) {
      &:hover {
        color: ${colors.cloud};
        transform: scale(1);
      }

      &:hover:before {
        box-shadow: inset 500px 0 0.01px ${theme.colors.foreground};
      }
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white}
    }
  `,
  'secondary-inverse': (theme) => css`
    background: ${theme.colors.foreground};
    color: ${colors.white};

    &:before {
      box-shadow: inset 0 0 0.01px ${colors.white};
    }

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.foreground};
        transform: scale(1);
      }

      &:hover:before {
        box-shadow: inset 500px 0 0.01px ${colors.white};
      }
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white}
    }
  `,
  navigation: (theme) => css`
    background: ${theme.colors.foreground};
    color: ${colors.white};

    &:before {
      box-shadow: inset 0 0 0 ${colors.cloud};
    }

    &:hover {
      color: ${theme.colors.foreground};
      transform: scale(1);
    }

    &:hover:before {
      box-shadow: inset 300px 0 0 ${colors.cloud};
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white}
    }

    @media (min-width: ${breakpoints.mobile}) {
      font-size: 14px;
    }
  `,
  'navigation-small-signed-out': (theme) => css`
    height: 32px;
    padding: 10px 20px 8px;
    background: ${theme.colors.foreground};
    color: ${colors.white};

    &:before {
      box-shadow: inset 0 0 0 ${colors.cloud};
    }

    &:hover {
      color: ${theme.colors.foreground};
    }

    &:hover:before {
      box-shadow: inset 300px 0 0 ${colors.cloud};
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white};
    }

    @media (min-width: ${breakpoints.mobile}) {
      font-size: 14px;
      color: ${colors.white};
    }
  `,
  'navigation-small-signed-in': (theme) => css`
    min-height: 32px;
    padding: 8px 20px;
    background: ${colors.cloud};
    color: ${theme.colors.foreground};

    &:before {
      box-shadow: inset 0 0 0 ${theme.colors.foreground};
    }

    &:hover {
      color: ${colors.cloud};
    }

    &:hover:before {
      box-shadow: inset 300px 0 0 ${theme.colors.foreground};
    }

    &:active {
      background: ${colors.han};
      color: ${colors.white};
    }

    @media (min-width: ${breakpoints.mobile}) {
      font-size: 14px;
    }
  `,
  filter: () => css`
    color: ${colors.darkerBlue};
    background: ${colors.white};
    border: 1px solid ${colors.darkerBlue};
    &:active {
      background: ${colors.white};
    }
  `,
};

export const StyledButton = styled.button`

  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.buttonSize === 'small' ? '80px' : props.width || 'auto')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
  min-width: ${props => (props.minWidth ? props.minWidth : '100px')};
  height: ${props => (props.buttonSize === 'small' ? '32px' : props.height || 'auto')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'none')};
  min-height: ${props => (props.minHeight ? props.minHeight : 'none')};
  padding: ${props => (props.buttonSize === 'small' ? '9px 10px 7px' : '14px 20px 11px')};
  border: 0;
  border-radius: 27px;
  font-weight: 600;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '1')};
  letter-spacing: 0.25px;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1);

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 27px;
    background: transparent;
    transition: all 0.6s cubic-bezier(0.5, 0.24, 0, 1);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:active {
    background: ${colors.han};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:hover::before,
  &.is-disabled,
  &.is-disabled:hover,
  &.is-disabled:hover::before {
    box-shadow: none;
    background: ${colors.platinum};
    color: ${colors.white};
    transition: none;
    cursor: default;
    border-color: ${colors.platinum};
  }

  &.is-loading {
    background: ${colors.platinum};
    color: ${colors.white};
  }

  .plushcare-loader {
    height: 15px;
    path {
      fill: ${colors.white};
    }
  }

  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${props => (props.fontSize ? props.fontSize : '24px')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '1')};

    .plushcare-loader {
      height: 24px;
    }
  }

  ${props => {
    if (!props.type) return null;
    return (props.type === 'submit' || props.type === 'button')
      ? buttonTypeStyles.primary(props.theme)
      : buttonTypeStyles[props.type](props.theme);
  }}

  &[type=navigation-small-signed-out] {
    background: ${props => props.theme.colors.foreground};

    &:hover {
      color: ${props => props.theme.colors.foreground};
    }

    &:hover:before {
      box-shadow: inset 300px 0 0 ${colors.cloud};
    }
  }
`;
