import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';
import { useFeatureFlag } from 'src/js/abTesting/hooks';
import CookieStorage from 'src/js/utils/CookieStorage';
import analytics from 'src/js/utils/analytics/Analytics';
import VisaIcon from 'public/img/icon_visa.svg';
import MastercardIcon from 'public/img/icon_mastercard.svg';
import AmericanExpressIcon from 'public/img/icon_american_express.svg';
import DiscoverIcon from 'public/img/icon_discover.svg';
import { UserPageData } from 'serverPropsProviders/user';
import { defaultUserData } from 'src/js/reducers/UserReducer';
import { EnterpriseUserStatus, ENTERPRISE_USER_STATUS } from 'src/js/nextgen/utils/constants';
import { storeUser } from 'src/js/actions/UserActions';
import {
  get, isSuccess, post, V2Error, V2Success,
} from 'src/js/utils/apiV2';
import { apiUrl, PlushcareWebAPI } from 'src/js/utils';
import { getFetcher } from 'src/js/endpoints/fetchers';
import { useSSRSWR } from 'src/js/endpoints/ServerSideSWR';
import axios from 'axios';

export interface SwrState<D> {
  data?: D,
  error?: V2Error,
  isValidating: boolean
}

export interface WalletInfo {
  wallet_type: string,
  dynamic_last4: string,
  is_apple_pay: boolean,
  is_google_pay: boolean,
}

export interface PaymentMethodRecord {
  payment_method_id: string,
  last4: string,
  brand: string,
  funding: string,
  country: string,
  wallet?: WalletInfo,
}

export interface UserStripePaymentInfo {
  stripe_customer_id?: string,
  alternative_payment_method?: PaymentMethodRecord,
  primary_payment_method?: PaymentMethodRecord,
  primary_is_wallet?: boolean,
  ephemeral_key?: boolean,
}

export type Membership = {
  subscription_uuid: string
  recurring_price: number,
  trial_period_days: number,
  interval: string
  discount_amount: null | number,
  variant: string,
  discount_appointment_price: number,
  repeat_patient_price: number
  initial_video_appointment_price: number
}

export type MembershipEnrollmentOffers = {
  membership: Membership
}

const IMAGES = new Map<string, string>([
  ['visa', VisaIcon],
  ['mastercard', MastercardIcon],
  ['amex', AmericanExpressIcon],
  ['discover', DiscoverIcon]]);
export const paymentMethodSelection = (
  newPaymentMethod?: UserStripePaymentInfo | null,
) => {
  // eslint-disable-next-line max-len
  const creditCard: {
    cardLastFour: string | null;
    cardBrand: string | null;
    stripeToken: string | null;
    // eslint-disable-next-line max-len
    numInvoiceErrors: unknown} | PaymentMethodRecord | undefined = newPaymentMethod?.primary_is_wallet ? newPaymentMethod.alternative_payment_method : newPaymentMethod?.primary_payment_method;
  let cardImage: string = '';
  const brand = creditCard?.brand && IMAGES.has(creditCard.brand.toLowerCase()) ? creditCard.brand.toLowerCase() : '';
  const last4 = creditCard?.last4 ? creditCard.last4 : '';
  if (brand && IMAGES.has(brand.toLowerCase())) {
    cardImage = IMAGES.get(brand.toLowerCase()) as string;
  }
  return {
    creditCard, brand, last4, cardImage,
  };
};

export const updateStripePaymentMethodInfoVer2 = async () => {
  try {
    const { data } = await PlushcareWebAPI.post(apiUrl.payments.getPaymentInformationVer2, {
      'require-ephemeral-key': false,
    }, true, true);
    return data as UserStripePaymentInfo;
  } catch (e) {
    if (e instanceof Error) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 500) {
        analytics.track('error', {
          message: 'Server Error - Failed to get payment information',
          error: e,
        });
      }
    }
    // Will revisit the above logic, but for now throwing this error to allow it to be handled by the caller.
    throw e;
  }
};

export const useUserStripePaymentInfo = (force: boolean = false) => {
  const { data: user } = useUserData();
  const { cache } = useSWRConfig();

  const cacheKey = user.is_logged_in ? apiUrl.payments.getPaymentInformationVer2 : null;
  const { data, mutate, error } = useSWR(
    cacheKey,
    updateStripePaymentMethodInfoVer2,
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(cacheKey) || force,
    },
  );
  return {
    data, mutate, isLoading: !data && !error, error,
  };
};

export const useUserData = (initialData?: UserPageData['data']) => {
  const dispatch = useDispatch();
  const { cache } = useSWRConfig();
  const {
    data,
    error,
    isValidating,
    mutate,
  } = useSSRSWR<UserPageData['data']>(apiUrl.getUserData, getFetcher, {
    initialData,
    // without this SWR will send queries each time when tab is blurred-focused back. We don't need that.
    revalidateOnFocus: false,
    onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
      if (err.message?.includes('status code 403')) {
        // avoid further calls for not logged user
        cache.set(apiUrl.getUserData, { defaultUserData });
        return;
      }
      if (retryCount >= (config?.errorRetryCount ?? 1)) return;
      setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
    },
  });

  const user = (data) || defaultUserData;

  useEffect(() => {
    // this is temp solution until we get rid of Redux completely
    if (data && data.id && !error && !isValidating) {
      dispatch(storeUser(user));
    }
  }, [data]);

  return {
    data: user,
    error,
    isValidating,
    mutate,
  };
};

interface EnterpriseStatusResponse {
  enterprise: EnterpriseUserStatus,
  enterprise_label: string,
}

export const getEnterpriseStatus = () => get<EnterpriseStatusResponse>(apiUrl.enterprise.check);

interface Authentication {
  email: string,
  password: string,
  client_id: string,
  client_secret: string,
}

export const authenticateUser = (postData: Authentication): Promise<UserPageData> => PlushcareWebAPI.post('patients/authenticate/', postData, true, true);

export const linkPatientAsEnterprise = (verification_id: string, exact_first_name_match: boolean = true) => post<
  { verification_id: string, exact_first_name_match?: boolean }, { success: boolean }>(
    apiUrl.enterprise.link,
    { verification_id, exact_first_name_match },
  );

const enterpriseUserFetcher = async () => {
  const response = await getEnterpriseStatus();
  if (isSuccess(response)) {
    return response.payload;
  }
  return null;
};

export const useEnterpriseStatus = () => {
  const { data: user } = useUserData();
  const { cache } = useSWRConfig();
  const cacheKey = user.is_logged_in ? apiUrl.enterprise.check : null;
  const { data, mutate, error } = useSWR(cacheKey, enterpriseUserFetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: !cache.get(cacheKey),
  });

  if (data) {
    const { enterprise, enterprise_label } = data;
    if (enterprise && enterprise_label) CookieStorage.set('ent_label', enterprise_label, null);
  }

  return { data, mutate, isLoading: !data && !error };
};

export const useIsEnterpriseUser = () => {
  const { data } = useEnterpriseStatus();
  return data?.enterprise === ENTERPRISE_USER_STATUS.FULL;
};

export const useIsPartialEnterpriseUser = () => {
  const { data } = useEnterpriseStatus();
  return data?.enterprise === ENTERPRISE_USER_STATUS.PARTIAL;
};

const genericEnterpriseUserStatuses: EnterpriseUserStatus[] = [
  ENTERPRISE_USER_STATUS?.FULL,
  ENTERPRISE_USER_STATUS?.PARTIAL,
];
export const useIsGenericEnterpriseUser = () => {
  const { data } = useEnterpriseStatus();
  return data && genericEnterpriseUserStatuses.includes(data.enterprise);
};

export const useIsEnterpriseCoverageUser = () => {
  const { data: user } = useUserData();
  return user.userprofile?.coverage.length && user.userprofile.coverage[0].coverage_type === 'enterprise';
};

export const useIsMedicareUser = () => {
  const { data: user } = useUserData();
  return !!user.userprofile?.coverage[0]?.is_medicare;
};

interface EligibleData {
  is_eligible: boolean,
  cap: Array<{ month: number, max: number, booked_appointment_ids: string[], }>
  is_enrolled: boolean,
  coverage_id: string,
  payer_id: string,
  payer_name: string,
}

export const useIsInsuranceEligible = () => {
  const [{ enabled: isCollaborativeCareEnabled }] = useFeatureFlag('collaborative-care-enabled', { autoUpdate: true });

  const { data } = useSWR<V2Success<EligibleData>>(apiUrl.getInsuranceEligibility, get);
  if (data?.status === 'success') {
    const {
      is_eligible, is_enrolled, cap, coverage_id,
    } = data.payload;
    return {
      isUserEligible: isCollaborativeCareEnabled && is_eligible,
      is_enrolled,
      cap,
      coverage_id,
    };
  }
  return { isUserEligible: false };
};

export const useOffers = () => {
  const {
    data, error, isValidating, mutate,
  } = useSSRSWR<V2Success<MembershipEnrollmentOffers>>(apiUrl.patients.getOffers, get, {});
  return {
    data: data?.payload,
    error,
    isValidating,
    mutate,
  };
};
