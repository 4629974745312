/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SessionStorage } from 'src/js/utils/storage';
import {
  getPCCampaign, storeAcquisitionCookies, getGoogleAnalyticsSessionStatus, getPageNavigationStatus,
} from '@accolade/care-analytics';
import CookieStorage from 'src/js/utils/CookieStorage';
import useCommonAnalyticsAttributes from 'utils/hooks/useCommonAnalyticsAttributes';
import Analytics from 'src/js/utils/analytics/Analytics';
import { getAppVariantDomain } from 'utils/hooks/useAppUrl';
import { useOneTrustContext } from '@accolade/care-analytics/react';
import { init as initFullStory } from '@fullstory/browser';
import * as env from 'utils/env';

export const isPathEqual = (a, b) => (a || '').replace(/\/$/, '') === (b || '').replace(/\/$/, '');

export const getPathForLocation = location => `${location.pathname.replace(/\/$/, '')}${location.search || ''}`;

const AnalyticsProvider = ({ children }) => {
  const router = useRouter();
  const { attributes: commonAnalyticsAttributes } = useCommonAnalyticsAttributes();
  const [isAnalyticsReady, setIsAnalyticsReady] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const domain = getAppVariantDomain();
  const { targetingCookiesAllowed } = useOneTrustContext();
  useEffect(() => {
    if (targetingCookiesAllowed) {
      initFullStory({
        orgId: 'o-1CVADE-na1',
        devMode: !env.is('prod'),
      });
    }
  }, [targetingCookiesAllowed]);

  useEffect(
    () => {
      // If an analytics library is ready, it sets a state variable (isAnalyticsReady) to true.
      // If the analytics library is not ready, it attempts to check up to 10 times (controlled by loadingCounter)
      // with a delay of 100 milliseconds between attempts.
      let timeout;

      const { analytics } = window;
      if (analytics?.ready) {
        analytics.ready(
          () => {
            setIsAnalyticsReady(true);
          },
        );
      } else if (loadingCounter < 10) {
        timeout = setTimeout(() => setLoadingCounter(x => x + 1), 100);
      }

      return () => clearTimeout(timeout);
    },
    [
      loadingCounter,
    ],
  );

  useEffect(
    () => {
      if (commonAnalyticsAttributes === undefined) {
        return;
      }
      Analytics.updateTraits(commonAnalyticsAttributes);
    },
    [commonAnalyticsAttributes],
  );

  useEffect(
    () => {
      if (router.asPath.includes('/[[...slug]]')) return;
      if (!isAnalyticsReady) return;
      let search = '?';
      for (const param in router.query) {
        if (Object.prototype.hasOwnProperty.call(router.query, param)) {
          search += `${param}=${router.query[param]}&`;
        }
      }
      search = search.slice(0, -1);

      if (!SessionStorage.getItem('initial_referrer')) {
        SessionStorage.setItem('initial_referrer', document.referrer);
      }
      CookieStorage.set('timezone_offset', -new Date().getTimezoneOffset());
      storeAcquisitionCookies(search, domain);
      const pcCampaign = getPCCampaign();
      const { urlHasChanged } = getPageNavigationStatus();

      if (!urlHasChanged) {
        return;
      }

      const {
        sessionStartTime, numberOfSessions, newSessionStarted, isFirstVisit,
      } = getGoogleAnalyticsSessionStatus();

      try {
        if (newSessionStarted) {
          const payload = {
            pc_campaign: pcCampaign,
            ga_session_id: sessionStartTime,
            ga_session_number: numberOfSessions,
          };
          Analytics.track('Google Analytics - Session Start', payload);
          if (isFirstVisit) {
            Analytics.track('Google Analytics - First Visit', payload);
          }
        }
        Analytics.page(pcCampaign);
      } catch (error) {
        Analytics.otherException(error);
      }
    },
    [
      router.asPath,
      isAnalyticsReady,
    ],
  );

  return (
    <div className="a-root" data-testid="analytics-provider">
      {children}
    </div>
  );
};

export default AnalyticsProvider;
