import { PrimaryCareBooking } from 'components/bookingWrappers/PrimaryCareLayout';
import { MethodSelection } from 'components/booking/MethodSelection';

const Method = () => {
  return (
    <PrimaryCareBooking>
      <MethodSelection />
    </PrimaryCareBooking>
  );
};

export default Method;
